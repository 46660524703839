import Vue from 'vue';
import Router from 'vue-router';

import BlogPosts from '@/components/Blog/BlogPosts.js'

const Home = () => import('@/views/Home.vue');
const Privacy = () => import('@/views/Privacy.vue');
const TOS = () => import('@/views/TOS.vue');
const Edit = () => import('@/views/Edit.vue');
const MailMerge = () => import('@/views/MailMerge.vue');
const Split = () => import('@/views/Split.vue');
const Organize = () => import('@/views/Organize.vue');
const Flatten = () => import('@/views/Flatten.vue');
const Fill = () => import('@/views/Fill.vue');
const BlogList = () => import('@/views/BlogList.vue');
const BlogPost = () => import('@/views/BlogPost.vue');

Vue.use(Router);



const blogRoutes = BlogPosts.map(post => {
  return {
    path: `${post.id}`,
    name: post.id,
    component: () => import(`@/components/Blog/markdown/${post.id}.md`),
    meta: {
      title: post.title,
      date: post.date,
      author: post.author,
      description: post.description,
    }
  }
})

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy,
    },
    {
      path: '/tos',
      name: 'tos',
      component: TOS,
    },
    {
      path: '/edit',
      name: 'edit',
      component: Edit,
    },
    {
      path: '/mailmerge',
      name: 'mailmerge',
      component: MailMerge,
    },
    {
      path: '/split',
      name: 'split',
      component: Split,
    },
    {
      path: '/organize',
      name: 'organize',
      component: Organize,
    },
    {
      path: '/flatten',
      name: 'flatten',
      component: Flatten,
    },
    {
      path: '/fill',
      name: 'fill',
      component: Fill,
    },
    {
      path: '/blog',
      name: 'blog',
      component: BlogList
    },
    {
      path: '/blog/posts',
      component: BlogPost,
      children: blogRoutes
    }
  ],
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0); //Scroll to top
  next();
});

export default router;

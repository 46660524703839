import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userMessage: '',
  },

  mutations: {
    setUserMessage(state, msg) {
      state.userMessage = msg;
    }
  },

  actions: {
  },

  getters: {
    userMessage: state => state.userMessage,
  },
});

<template>
  <v-app id="app" class="white">
    <router-view />
  </v-app>
</template>

<script>

export default {
}
</script>

<style>
#app {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>

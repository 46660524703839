export default [
  {
    id: 'how-to-split-pdf-pages',
    date: 'August 12, 2021',
    author: 'Stephen Price',
    title: 'How To Split A PDF FREE',
    description: 'Splitting PDFs with PDFZero is easy',
    hashtags: 'pdfzero'
  },
  {
    id: 'updates-july-2021',
    date: 'July 14, 2021',
    author: 'Stephen Price',
    title: 'Looks a little different...',
    description: 'Whats new July 2021',
    hashtags: 'pdfzero'
  },
  {
    id: 'how-to-mailmerge-pdf',
    date: 'July 25, 2021',
    author: 'Stephen Price',
    title: 'How to Mail Merge PDFs for free',
    description: 'Fill out hundreds of PDFs at once',
    hashtags: 'pdfzero'
  },
  {
    id: 'how-to-flatten-pdf',
    date: 'July 14, 2021',
    author: 'Stephen Price',
    title: 'Use PDFZero to Flatten PDF forms',
    description: 'Easily flatten PDF forms - no upload required',
    hashtags: 'pdfzero'
  },
]

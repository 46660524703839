import Vue from 'vue';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';

Vue.config.productionTip = false;

// Firebase
import firebase from 'firebase/app';
import 'firebase/analytics';
const firebaseConfig = {
  apiKey: "AIzaSyDCtIPnaF_6s8X09XDOstcr3SzNdVeGrxk",
  authDomain: "pdfzero-bc291.firebaseapp.com",
  projectId: "pdfzero-bc291",
  storageBucket: "pdfzero-bc291.appspot.com",
  messagingSenderId: "190850631404",
  appId: "1:190850631404:web:b36013a0534330a8bc87c9",
  measurementId: "G-MH95J0ZCRJ"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
Vue.prototype.$analytics = firebase.analytics();


import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
